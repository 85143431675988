<!--
 * @Descripttion: 修改评审专业
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-07 16:54:03
-->
<template>
	<div>
		<div>
			<ul>
				<li v-for="(item,index) in list" :key="index" :class="{test : index ==0}">
					<div class="flex-item" @click="item.click = !item.click" style="justify-content:space-between">
            <div style="width:calc(100% - 2rem); flex-shrink: 0; display:flex; align-items:center;">
              <van-icon :color="item.checked ? '#00CCB2' : '#D9D9D9'" :name="item.checked ? 'checked':'circle'" @click.stop="selectedParent(item)"
              size="0.5rem" />
              <h3 class="mar-l2">{{ item.majorAlias || item.name }}</h3>
            </div>
            <div>
              <p class="remark" style="right:1rem" v-if="item.checked" @click="editExplain(item)">修改名称</p>
              <van-icon name="play" :class="{arrow:true,'arrow-up':item.click}" />
            </div>
					</div>
					<ul v-show="item.click">
						<li v-for="(child,i) in item.children" :key="child.id" class="son">
							<div class="flex-item" @click="selectedSon(item,child)">
								<van-icon :color="child.checked ? '#00CCB2' : '#D9D9D9'" :name="child.checked ? 'checked':'circle'" size="0.5rem" />
								<h3 class="mar-l2 name van-multi-ellipsis--l2">{{showMajorAlias(child)}}</h3>
							</div>

							<p class="remark" v-if="child.checked" @click="editExplain(child)">修改名称</p>
						</li>
					</ul>
				</li>
			</ul>
		</div>
		<div class="h2 bg"></div>
		<div class="fixed-bottom pad25 whiteBg">
			<button class="btn w-100" @click="onSave">保存选择</button>
			<!-- <router-link tag="button" :to="{path : '/profession/info'}" class="btn w-100" >保存选择</router-link> -->
		</div>

		<van-popup v-model="showRemark" round style="width:80%; min-height:20%">
			<div class="pad">
				<h3 class="center f5">修改名称</h3>
				<input type="text" class="explainInput" v-model="remark">
				<div class="flex mar-t">
					<button class="btn cancel div4" style="margin-right:10%" @click="showRemark = false">取消</button>
					<button class="btn div4" @click="doEditExplain">确认</button>
				</div>
			</div>
		</van-popup>

	</div>
</template>

<script>
	import {
		Icon,
		Popup
	} from 'vant'
	export default {
		data() {
			return {
				list: [],
				gongchengList: [],
				huowuList: [],
				fuwuList: [],
				queryParam: {
					orgId: "d6f2e5258fe94834b1e8e7661a28fdc7",
				},
				pageParams: {
					pageNo: 1,
					pageSize: 20
				},
				showRemark: false,
				remark: '',
				editChild: {}, //修改的节点对象
				workweixin: false,				//企业微信
				openUserid:	"",
			}
		},

		props: {
			activeIndex: {
				type: [String, Number],
				required: true
			}
		},
		watch: {
			activeIndex(newV) {
				if (newV == 0) this.list = this.gongchengList
				else if (newV == 1) this.list = this.huowuList
				else if (newV == 2) this.list = this.fuwuList
			}
		},
		created() {
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//
			if (this.$ls.get("edCurrentOrgExternal")) {
				this.orgExternal = this.$ls.get("edCurrentOrgExternal");
			}
			//获取公司信息
			if (this.$ls.get("edCurrentOrg")) {
				this.queryParam.orgId = this.$ls.get("edCurrentOrg").id;
			};
			this.getTreeData();

			this.$emit('onShowSearch', false)
			this.$emit('onRouteIndex', 1)
		},
		components: {
			[Icon.name]: Icon,
			[Popup.name]: Popup
		},

		methods: {

			/**
			 * @Description: 一级专业的全选与取消
			 * @Author: Niklaus
			 * @Date: 2021-01-06 15:18:40
			 * @param {*} item
			 */
			selectedParent(item) {
				item.checked = !item.checked
				if (item.children != null) {
					for (let i = 0; i < item.children.length; i++) {
						const ele = item.children[i].checked = item.checked;
					}
				}
			},

			/**
			 * @Description: 二级专业的 选中与取消
			 * @Author: Niklaus
			 * @Date: 2021-01-06 15:08:51
			 * @param {*} item ： 一级专业
			 * @param {*} child： 二级专业
			 */
			selectedSon(item, child) {
				child.checked = !child.checked
				let hasChecked = false
				if (item.children != null) {
					for (let i = 0; i < item.children.length; i++) {
						const ele = item.children[i];
						if (ele.checked) {
							hasChecked = true
							break
						}
					}
				}
				item.checked = hasChecked
			},
			//保存
			onSave() {
				let allData = {};
				allData.gongchengList = this.gongchengList;
				allData.huowuList = this.huowuList;
				allData.fuwuList = this.fuwuList;
				allData.orgId = this.queryParam.orgId;

				this.$ajax({
					method: "put",
					url: "/gateway/ed/wx/wxEdReviewMajorTwoThreeClassController/updateMajorData",
					headers: {
						"X-Access-Token": this.$ls.get("token"),
					},
					params: {
						openid: (this.workweixin?this.openUserid:this.$ls.get("openid"))
					},
					data: allData
				}).then((res) => {
					if (res.data.success) {
						this.$toast.success("设置成功")
						setTimeout(() => {
							this.$router.push('/profession/info')
						}, 500);
					} else {
						this.$toast.fail(res.data.message)
					}
				});

			},
			//获取专业树结构
			getTreeData() {
				var params = Object.assign({}, this.queryParam)
				params.pageNo = this.pageParams.pageNo;
				params.pageSize = this.pageParams.pageSize;
				params.openid = this.workweixin?this.openUserid:this.$ls.get("openid")
         /** 添加测试数据 */
        // params.openid = 'woRz2sCAAAsDaCf2El5R-ir9TBNAJl8g'
        // params.orgId = 'wwcb3084429faea701'
				this.$ajax({
					methods: 'get',
					url: '/gateway/ed/wx/wxEdReviewMajorTwoThreeClassController/openList/',
					headers: {
						'X-Access-Token': this.$ls.get("token")
					},
					params: params,
				}).then(res => {
					if (res.data.success) {

						this.gongchengList = res.data.result.gongchengList;
						this.huowuList = res.data.result.huowuList;
						this.fuwuList = res.data.result.fuwuList;
						if (this.activeIndex == 0)
							this.list = res.data.result.gongchengList
						else if (this.activeIndex == 1)
							this.list = res.data.result.huowuList;
						else if (this.activeIndex == 2)
							this.list = res.data.result.fuwuList;
					} else {
						this.$toast.fail("失败")
						console.log(res.data.message)
					}
				});
			},
			/**
			 * @Description: 调用搜索：若搜索结果无数据，通知父级 隐藏大类
			 * @Author: Niklaus
			 * @Date: 2021-01-28 16:37:04
			 * @param {*} searchV： 父级页面的 搜索内容
			 */
			doSearch(searchV) {
				this.searchV = searchV
				this.hasSearchData = false
				if (!this.hasSearchData) {
					this.$emit('onTitle', false)
				} else {
					this.$emit('onTitle', true)
				}
			},
			//修改备注-弹层
			editExplain(item) {
				this.editChild = item;
				this.showRemark = true
				this.remark = this.showMajorAlias(item);
			},
			//修改备注-保存
			doEditExplain() {
				if (this.remark == '') {
					this.$toast.fail('请填写名称')
					return
				} else if (this.remark.length > 200) {
					this.$toast.fail('名称过长，请修改！')
					return
				}
				// //工程类
				// if (this.gongchengList.length > 0) {
				// 	for (let i = 0; i < this.gongchengList.length; i++) {
				// 		if (this.gongchengList[i].children != null && this.gongchengList[i].children.length > 0) {
				// 			for (let j = 0; j < this.gongchengList[i].children.length; j++) {
				// 				if (this.gongchengList[i].children[j].id == this.editChild.id) {
				// 					this.gongchengList[i].children[j].majorAlias = this.remark;
				// 					this.showRemark = false;
				// 					return;
				// 				}
				// 			}
				// 		}
				// 	}
				// }
				// //货物类
				// if (this.huowuList.length > 0) {
				// 	for (let i = 0; i < this.huowuList.length; i++) {
				// 		if (this.huowuList[i].children != null && this.huowuList[i].children.length > 0) {
				// 			for (let j = 0; j < this.huowuList[i].children.length; j++) {
				// 				if (this.huowuList[i].children[j].id == this.editChild.id) {
				// 					this.huowuList[i].children[j].majorAlias = this.remark;
				// 					this.showRemark = false;
				// 					return;
				// 				}
				// 			}
				// 		}
				// 	}
				// }
				// //服务类
				// if (this.fuwuList.length > 0) {
				// 	for (let i = 0; i < this.fuwuList.length; i++) {
				// 		if (this.fuwuList[i].children != null && this.fuwuList[i].children.length > 0) {
				// 			for (let j = 0; j < this.fuwuList[i].children.length; j++) {
				// 				if (this.fuwuList[i].children[j].id == this.editChild.id) {
				// 					this.fuwuList[i].children[j].majorAlias = this.remark;
				// 					this.showRemark = false;
				// 					return;
				// 				}
				// 			}
				// 		}
				// 	}
				// }
        this.editChild.majorAlias = this.remark
				this.showRemark = false;
			},
			//全部重置备注
			resetRemark() {
				//工程类
				if (this.gongchengList.length > 0) {
					for (let i = 0; i < this.gongchengList.length; i++) {
						if (this.gongchengList[i].children != null && this.gongchengList[i].children.length > 0) {
							for (let j = 0; j < this.gongchengList[i].children.length; j++) {
								this.gongchengList[i].children[j].majorAlias = '';
							}
						}
					}
				}
				//货物类
				if (this.huowuList.length > 0) {
					for (let i = 0; i < this.huowuList.length; i++) {
						if (this.huowuList[i].children != null && this.huowuList[i].children.length > 0) {
							for (let j = 0; j < this.huowuList[i].children.length; j++) {
								this.huowuList[i].children[j].majorAlias = '';
							}
						}
					}
				}
				//服务类
				if (this.fuwuList.length > 0) {
					for (let i = 0; i < this.fuwuList.length; i++) {
						if (this.fuwuList[i].children != null && this.fuwuList[i].children.length > 0) {
							for (let j = 0; j < this.fuwuList[i].children.length; j++) {
								this.fuwuList[i].children[j].majorAlias = '';
							}
						}
					}
				}

			},
			//优先显示备注
			showMajorAlias(item) {
				if (item.majorAlias != null && item.majorAlias != '') {
					return item.majorAlias
				} else {
					return item.majorName
				}
			}
			//下一个方法
		}
	}
</script>

<style scoped>
	.flex-item {
		margin-top: 0.1rem;
		position: relative;
		padding: 0.1rem 0.5rem
	}

	.son {
		margin-left: 0.5rem;
		position: relative;
	}

	.remark {
		position: absolute;
		right: 0.1rem;
		top: 0.1rem;
		width: 1.8rem;
		font-weight: 400;
		color: #00CCB2;
	}

	.name {
		width: calc(100% - 3rem);
	}

	.explainInput {
		min-height: 1rem;
		line-height: 0.8rem;
		border-bottom: 1px solid #d9d9d9;
		margin-top: 1rem;
		width: 100%;
	}

	.test {
		padding-top: 0.1rem;
	}
</style>
